<template>
  <div class="mensalidades-isp">
    <h1 class="titulo-card fs-4">Relatórios / Boletos vencidos</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button @click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
        <div class="input-group mb-3">
          <input
              v-model="search.q"
              v-on:keyup.enter="handlePesquisa(true)"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="handlePesquisa(true)" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col">
            <h5 class="mb-0 text-info border-start border-info"><font-awesome-icon :icon="['fas', 'file']" fixed-width/>Boletos</h5>
            <p class="mb-0 text-muted ps-1">
              Relação de boletos vencidos dos clientes ativos
            </p>
          </div>
        </div>
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th>Cliente</th>
              <th>Vencimento</th>
              <th>Nosso número</th>
              <th>Código do integrador</th>
              <th class="text-center" width="90">Valor</th>
            </tr>
          </thead>
          <tbody>
            <!-- definir a propriedade (style="vertical-align: middle;") como uma classe css global, ver onde precisa aplicar e fazê-lo  -->
            <tr v-for="boleto in boletos" :key="boleto.id" style="vertical-align: middle;" class="cursor-pointer text-danger">
              <td scope="row">{{customerName(boleto.customerId) || '-'}}</td>
              <td>{{godate().toMysqlUTC(boleto.dueDate)}} - <span class="text-muted">há {{ daysPassed(boleto) }} dias</span></td>
              <td>{{boleto.nossoNumero}}</td>
              <td>{{boleto.bankBoletoId}}</td>
              <td class="text-center">R$ {{formataMoeda(boleto.amount)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
    </div>
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'
import godate from '@/modules/godate'
import uuid from '@/modules/uuid'

export default {
  name: 'ReportOverdueBoletos',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      search: {
        q: '',
      },
      boletos: [],
      customers: [],
    }
  },

  methods: {
    godate() {
      return godate;
    },
    uuid() {
      return uuid;
    },

    handlePesquisa(resetPage) {
      const q = this.search.q;
      let params = this.paginador.requestParams;

      if (resetPage) {
        params = this.paginador.requestParamsFirst;
      }

      loading(true);
      api.get(`/reports/overdue-boletos?${params}&q=${q}&orderBy[due_date]=asc`).then(res => {
        loading(false);
        this.boletos = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
        this.customers = res.data.aggregators.customers;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    customerName(customerId) {
      const customer = this.customers.find(x => x.id === customerId);
      if (!customer) {
        return '';
      }

      return customer.name;
    },

    daysBetween(date1, date2) {
      const seconds = Math.floor((date1 - date2) / 1000);
      let days = Math.floor(seconds / 86400) + 1;
      if (days < 0) {
          days *= -1;
      }

      return days;
    },

    daysPassed(boleto) {
      const today = new Date();
      const dueDate = godate.toDate(boleto.dueDate);

      return this.daysBetween(today, dueDate);
    },

    //TODO: fazer esta função ser global
    insereChar(texto, char, posicao) {
        return texto.slice(0, posicao) + char + texto.slice(posicao);
    },
    padLeft(variavel, qtdChars, str){
        variavel = String(variavel);
        if (variavel.length >= qtdChars) {
            return variavel;
        }
        return Array(qtdChars - variavel.length + 1).join(str||'0') + variavel;
    },
    formataMoeda(valorInt) {
        if (!valorInt) {
            valorInt = 0;
        }
        let negativo = false;
        if (valorInt < 0) {
            negativo = true;
            valorInt = valorInt * (-1);
        }

        let vOut = this.padLeft(valorInt, 3) || '000';
        vOut = this.insereChar(vOut, ',', vOut.length - 2);
        let charLen = 6;
        let textLen = vOut.length;

        while (textLen > charLen) {
            vOut = this.insereChar(vOut, '.', textLen - charLen);
            charLen += 4;
            textLen = vOut.length;
        }

        if (negativo === true) {
            vOut = '-' + vOut;
        }

        return vOut;
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },
  },
  created() {
    this.handlePesquisa();
  }
}


</script>
<style>
</style>