/**
 * @param {Date} input
 */
 function fromDate(input) {
    // if (typeof input !== Date) {
    //     return '';
    // }

    return input.toJSON();
}

export default fromDate