/**
 * @param {string} input uuid string
 * @returns {bool}
 */
 function isEmpty(input) {
    switch (true) {
        case (typeof input !== 'string'):
        case (!input):
        case (input === '00000000-0000-0000-0000-000000000000'):
            return true;

        default:
            return false;
    }
}

export default isEmpty