import toDate from './toDate';
/**
 * @param {string} input input date in golang format
 * @param {string} withName month name: false | true | 'full'
 * @param {string} sep separator for without month name: default: '/'
 * @returns {string} brazillian formatted date: 1 Jan 2022 | 1 Janeiro 2022 | 1/1/2022
 */
 function toBrazilDate(input, withName, sep) {
    if (typeof input !== 'string') {
        return '';
    }

    const d = toDate(input);

    if (withName) {
        const ns = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        const fns = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        let month = '';

        switch (withName) {
            case 'full':
                month = fns[d.getMonth()];
                break;

            default:
                month = ns[d.getMonth()];
        }

        return `${d.getDate()} ${month} ${d.getFullYear()}`;
    }

    if (!sep) {
        sep = '/';
    }

    return `${padLeft(d.getDate(), 2)}${sep}${padLeft(d.getMonth()+1, 2)}${sep}${d.getFullYear()}`;
}

function padLeft(nr, n, str){
    return Array(n-String(nr).length+1).join(str||'0')+nr;
}

export default toBrazilDate