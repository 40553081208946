/**
 * @param {string} input
 * @returns {Date}
 */
 function toDate(input) {
    if (typeof input !== 'string') {
        return undefined;
    }

    var d = new Date(input);

    // const dates = input.split('T')[0].split('-');

    var out = new Date(Date.UTC(
        d.getUTCFullYear(),
        d.getUTCMonth(),
        d.getUTCDate(),
    ));

    return out;
}

export default toDate