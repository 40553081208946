import fromDate from "./fromDate";

/**
 * @param {string} input
 */
 function fromMysql(input) {
    if (typeof input !== 'string') {
        return undefined;
    }

    if (input.trim().includes(' ')) {
        const vals = input.split(' ')[0].split('-');
        const time = input.split(' ')[1].split(':');
        const d = new Date(Date.UTC(vals[0], vals[1]-1, vals[2], time[0], time[1], time[2]));

        return fromDate(d)
    }

    const vals = input.split('-')
    const d = new Date(Date.UTC(vals[0], vals[1]-1, vals[2]));

    return fromDate(d)
}

export default fromMysql