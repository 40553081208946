import toDateTime from './toDateTime';
import toBrazilDate from './toBrazilDate';
/**
 * @param {string} input input date in golang format
 * @param {string} withName month name: false | true | 'full'
 * @param {string} sep separator for without month name: default: '/'
 * @returns {string} brazillian formatted date: 1 Jan 2022 | 1 Janeiro 2022 | 1/1/2022
 */
 function toBrazilDateTime(input, withName, sep, hourSep) {
    if (typeof input !== 'string') {
        return '';
    }

    /** @type Date */
    const d = toDateTime(input);

    if (!sep) {
        sep = '/';
    }
    if (!hourSep) {
        hourSep = ':';
    }

    const dateStr = toBrazilDate(input, withName, sep)

    return `${dateStr} ${padLeft(d.getHours(), 2)}${hourSep}${padLeft(d.getMinutes(), 2)}${hourSep}${padLeft(d.getSeconds(), 2)}`;
}

function padLeft(nr, n, str){
    return Array(n-String(nr).length+1).join(str||'0')+nr;
}

export default toBrazilDateTime