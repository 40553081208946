import toDate from "./toDate";

function toMysql(input) {
    const dt = toDate(input);
    const d = strZero(dt.getUTCDate(), 2);
    const m = strZero(dt.getUTCMonth()+1, 2);
    const y = dt.getUTCFullYear();

    return `${y}-${m}-${d}`;
}

function strZero(numero, zeros) {
    return Array(zeros-String(numero).length+1).join('0') + numero;
}

export default toMysql